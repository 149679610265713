import React from 'react';
import { bool, node } from 'prop-types';
import theme from '../theme/theme';

import IsElement from './is-element';
import CenteredSpinner from './centered-spinner';

const Button = ({ block, children, is = 'button', isLoading, ...rest }) => {
  return (
    <IsElement
      css={{
        background: 'transparent',
        backgroundColor: 'white',
        backgroundImage: 'linear-gradient(rgb(159, 166, 251), rgb(134, 142, 232))',
        border: 'none',
        borderColor: 'initial',
        borderImage: 'initial',
        borderRadius: 4,
        borderStyle: 'none',
        borderWidth: 'initial',
        color: theme.colors.lightest,
        display: block ? 'block' : 'inline-block',
        height: 48,
        outline: 'none',
        textAlign: 'center',
        textDecoration: 'none',
        userSelect: 'none',
        verticalAlign: 'middle',
        webkitAppearance: 'none',
        webkitFontSmoothing: 'antialiased',
        whiteSpace: 'nowrap',
        width: '100%',
        ...theme.text['400'],
        ':not([disabled])': {
          ':hover': {
            cursor: !isLoading ? 'pointer' : 'default',
          },
        },
        ...(isLoading ? { pointerEvents: 'none '} : {}),
        ':disabled': {
          cursor: 'default',
          opacity: 0.5,
        },
        ':focus': {
          boxShadow: 'rgba(67, 90, 111, 0.14) 0px 0px 2px inset, rgb(134, 142, 232) 0px 0px 0px 1px inset, rgba(134, 142, 232, 0.15) 0px 0px 0px 3px',
          outline: 'none',
        },
      }}
      is={is}
      {...rest}
    >
      {isLoading ? <CenteredSpinner /> : children}
    </IsElement>
  );
};

Button.propTypes = {
  children: node.isRequired,
  block: bool,
  isLoading: bool,
};

Button.defaultProps = {
  block: true,
  isLoading: false,
};

export default Button;
