import React from 'react';
import { number } from 'prop-types';
import { keyframes } from '@emotion/core';

const loadingKeyframes = keyframes`
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
`;

const loadingCircleKeyframes = keyframes`
  from {
    stroke-dashoffset: 600;
  }

  to {
    stroke-dashoffset: 0;
  }
`;

const Spinner = ({ size, ...rest }) => {
  return (
    <div css={{ height: size, width: size }} {...rest}>
      <svg
        css={{ animation: `2s linear 0s infinite normal none running ${loadingKeyframes}` }}
        x="0px"
        y="0px"
        viewBox="0 0 150 150"
      >
        <circle
          css={{
            strokeDashoffset: 600,
            strokeDasharray: 300,
            strokeWidth: 12,
            strokeMiterlimit: 10,
            strokeLinecap: 'round',
            stroke: 'rgba(255, 255, 255, 0.9)',
            fill: 'transparent',
            animation: `1.6s cubic-bezier(0.4, 0.15, 0.6, 0.85) 0s infinite normal none running ${loadingCircleKeyframes}`,
          }}
          cx="75"
          cy="75"
          r="60"
        />
      </svg>
    </div>
  );
};

Spinner.propTypes = {
  size: number,
};

Spinner.defaultProps = {
  size: 32,
};

export default Spinner;
