import React from 'react';
import { node, string, bool } from 'prop-types';
import theme from '../theme/theme';

import IsElement from './is-element';

const Label = ({ children, ...rest }) => {
  return (
    <label css={{ display: 'block', marginBottom: 8, ...theme.text[500] }} {...rest}>
      {children}
    </label>
  )
}

Label.propTypes = {
  children: node.isRequired,
};

const Input = ({ block, label, id, isInvalid,  is, validationMessage, ...rest }) => {
  return (
    <>
      {label ? <Label htmlFor={id}>{label}</Label> : null}
      <IsElement
        css={{
          background: theme.colors.dark,
          borderColor: 'initial',
          borderImage: 'initial',
          borderRadius: 4,
          borderStyle: 'none',
          borderWidth: 'initial',
          boxShadow: 'rgba(255, 255, 255, 0.3) 0px 0px 0px 1px inset, rgba(67, 90, 111, 0.14) 0px 1px 2px inset',
          color: theme.colors.lightest,
          display: 'inline-block',
          height: 48,
          lineHeight: 24,
          paddingLeft: 12,
          paddingRight: 12,
          WebkitAppearance: 'none',
          width: block ? '100%' : 'auto',
          ...theme.text['400'],
          '::placeholder': {
            color: theme.colors.light,
          },
          ':focus': {
            boxShadow: 'rgba(67, 90, 111, 0.14) 0px 0px 2px inset, rgb(134, 142, 232) 0px 0px 0px 1px inset, rgba(134, 142, 232, 0.15) 0px 0px 0px 3px',
            outline: 'none',
          },
          ...(isInvalid ? {
            boxShadow: `inset 0 0 0 1px ${theme.colors.danger}, inset 0 1px 2px rgba(67, 90, 111, 0.14)`,
          } : {}),
        }}
        id={id}
        is={is}
        {...rest}
      />
      {validationMessage ? <small css={{ color: theme.colors.danger, ...theme.text['300'] }}>{validationMessage}</small> : null}
    </>
  );
};

Input.propTypes = {
  block: bool,
  id: string.isRequired,
  isInvalid: bool,
  label: node,
};

Input.defaultProps = {
  block: true,
  is: 'input',
  isInvalid: false,
  label: null,
};

export default Input;
