import React, { useCallback, useMemo, useReducer } from 'react';
import { object as yupObject, string as yupString } from 'yup';

import Button from '../components/button';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Nav from '../components/nav';
import Input from '../components/input';
import Textarea from '../components/textarea';
import theme from '../theme/theme';

const schema = yupObject().shape({
  name: yupString().required(),
  email: yupString()
    .required()
    .email(),
  message: yupString().required(),
});

const ACTIONS = {
  CLEAR_ERRORS: 'CLEAR_ERRORS',
  SET_ERRORS: 'SET_ERRORS',
  SET_FIELD: 'SET_FIELD',
  SUBMIT: 'SUBMIT',
};

const initialState = {
  isSubmitting: false,
  fields: {
    email: '',
    name: '',
    message: '',
  },
  errors: {
    email: '',
    name: '',
    message: '',
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ERRORS: {
      return {
        ...state,
        errors: { ...initialState.errors },
      };
    }
    case ACTIONS.CLEAR_FIELD_ERROR: {
      const {
        payload: { field },
      } = action;

      return {
        ...state,
        errors: {
          ...state.errors,
          [field]: '',
        },
      };
    }
    case ACTIONS.SET_FIELD: {
      const {
        payload: { key, value },
      } = action;

      return {
        ...state,
        fields: {
          ...state.fields,
          [key]: value,
        },
        errors: {
          ...state.errors,
          [key]: '',
        },
      };
    }
    case ACTIONS.SUBMIT: {
      return {
        ...state,
        isSubmitting: true,
      };
    }
    case ACTIONS.SET_ERRORS: {
      const {
        payload: { errors },
      } = action;

      return {
        ...state,
        errors,
      };
    }
    default:
      return state;
  }
};

const ContactPage = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const onSubmit = useCallback(() => {
    dispatch({ type: ACTIONS.SUBMIT });
  }, [dispatch]);
  const {
    fields: { name, email, message },
    errors,
    isSubmitting,
  } = state;
  const isValid = useMemo(() => {
    return schema.isValidSync({ name, email, message });
  }, [name, email, message]);
  const onBlur = React.useCallback(() => {
    try {
      schema.validateSync({ name, email, message }, { abortEarly: false });
    } catch (e) {
      dispatch({
        type: ACTIONS.SET_ERRORS,
        payload: {
          errors: e.inner.reduce((result, error) => {
            return {
              ...result,
              [error.path]: error.message,
            };
          }, {}),
        },
      });
    }
  }, [dispatch, email, name, message]);

  return (
    <Layout>
      <SEO title="Contact" />
      <Nav />
      <main
        css={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '16px 0',
        }}
      >
        <form
          action="https://8vknru0ekl.execute-api.us-east-1.amazonaws.com/p/"
          css={{ maxWidth: '65ch', marginLeft: 'auto', marginRight: 'auto', width: '100%' }}
          encType="multipart/form-data"
          method="POST"
          onSubmit={onSubmit}
        >
          <legend css={{ ...theme.headings['900'], marginBottom: 16, textAlign: 'center' }}>
            Contact
          </legend>
          <div css={{ marginBottom: 16 }}>
            <Input
              id="name"
              type="text"
              label="Name"
              name="name"
              placeholder="Name"
              required
              onChange={({ target: { value } }) => {
                dispatch({ type: ACTIONS.SET_FIELD, payload: { key: 'name', value } });
              }}
              value={name}
              validationMessage={name ? errors.name : ''}
            />
          </div>
          <div css={{ marginBottom: 16 }}>
            <Input
              id="email"
              type="email"
              label="Email"
              name="email"
              placeholder="Email"
              required
              onChange={({ target: { value } }) => {
                dispatch({ type: ACTIONS.SET_FIELD, payload: { key: 'email', value } });
              }}
              onBlur={onBlur}
              value={email}
              validationMessage={email ? errors.email : ''}
            />
          </div>
          <div css={{ marginBottom: 24 }}>
            <Textarea
              id="message"
              label="Message"
              name="message"
              placeholder="Message"
              required
              onChange={({ target: { value } }) => {
                dispatch({ type: ACTIONS.SET_FIELD, payload: { key: 'message', value } });
              }}
              value={message}
              validationMessage={message ? errors.message : ''}
            />
          </div>
          <input
            name="redirectLocation"
            type="hidden"
            css={{ display: 'none' }}
            value="https://madebytr.com/contact/message-sent"
          />
          <input
            name="errorLocation"
            type="hidden"
            css={{ display: 'none' }}
            value="https://madebytr.com/contact/error"
          />
          <Button isLoading={isSubmitting} disabled={!isValid} type="submit">
            Send
          </Button>
        </form>
      </main>
    </Layout>
  );
};

export default ContactPage;
