import React from 'react';
import { func, string, oneOfType } from 'prop-types';

const IsElement = ({ is, ...rest }) => {
  const Element = `${is}`;

  return <Element {...rest} />;
};

IsElement.propTypes = {
  is: oneOfType([func, string]),
};

IsElement.defaultProps = {
  is: 'div',
};

export default IsElement;
