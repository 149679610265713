import React from 'react';

import Input from './input';

const Textarea = props => {
  return (
    <Input is="textarea" css={{ minHeight: 80, lineHeight: '20px', paddingTop: 8 }} {...props} />
  );
};

export default Textarea;
